<template>
  <div v-if="houseData">
   <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="banner-wrap">
        <Banner :bannerList="houseData.bannerList" height="750px" arrow="never" indicator-position="none"></Banner>
        <div class="hint" :style="hintCss">
          <div class="page">
            <div class="left">
              小院·未来城{{houseType}}㎡
            </div>
            <div class="right">
              <div class="area">
                <!-- <span>7500</span>元/平 -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main">

        <div class="basic page">
          <table border="1">
            <tr class="table-head">
              <th colspan="4">基础信息</th>
            </tr>
            <tr v-for="(basic,index) of basicList" :key="index">
              <td v-for="(item,index2) of basic" :key="index2">{{item}}</td>
            </tr>
          </table>
        </div>

        <div class="xiangce">
          <div class="page">
            <div class="title">
              <span>楼盘相册</span>
              <span class="more">
                查看更多
              </span>
            </div>
            <div class="warp">
              <div class="item" v-for="(item,index) of houseData.photoAlbum" :key="index">
                <img :src="item.image" alt="">
                <div class="title">
                  {{item.title}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vr">
          <div class="page">
            <div class="title">VR全景展示</div>
            <div class="desc">高度还原真实空间，身临其境，全面看房</div>
            <div class="content">
              <iframe :src="vr" frameborder="0"></iframe>
            </div>
          </div>
        </div>

        <div class="xingjiabi" :style="{backgroundImage: 'url('+ houseData.costEffective +')'}">
          <div class="page">
            <Banner :bannerList="cardList" height="265px" type="card" arrow="never" id="xingjiabi-banner"></Banner>
          </div>
        </div>

        <div class="shuang">
          <div class="page">
            <div class="title">私家庭院 诗意栖居</div>
            <div class="desc">前庭后院 私享双花园</div>
            <div class="desc">围合院落 亲近大自然</div>
            <div class="desc">回归本真 提升生活品质</div>
            <div class="desc">营造城市中绿色诗意般的田园生活</div>
            <div class="warp">
              <div class="item">
                <img src="../../../assets/images/shuang1.png" alt="">
                <div class="title">室外花园</div>
              </div>
              <div class="item">
                <img src="../../../assets/images/shuang2.png" alt="">
                <div class="title">宠物之家</div>
              </div>
              <div class="item">
                <img src="../../../assets/images/shuang3.png" alt="">
                <div class="title">会客空间</div>
              </div>
              <div class="item">
                <img src="../../../assets/images/shuang4.png" alt="">
                <div class="title">儿童活动</div>
              </div>
            </div>
          </div>
        </div>

        <div class="chewei">
          <img src="../../../assets/images/chewei.jpg" alt="">
        </div>

        <div class="huxing">
          <div class="page">
            <img src="../../../assets/images/ditan.jpg" alt="">
            <div class="right">
              <div class="title">低碳建筑 智慧科技</div>
              <!-- <div class="title">空间划分灵活</div> -->
              <div class="desc">
                小院生活具备一整套低碳建筑解决方案，将绿色环保、节能减排作为建筑设计的标配，实力打造健康舒适、科技创新的生活空间，为智慧社区提供整体解决方案。
              </div>
            </div>
          </div>
        </div>

        <div class="icon">
          <div class="page">
            <div class="warp">
              <div class="item">
                <i class="iconfont icon-safe"></i>
                <div class="title">抗震性最好</div>
                <div class="desc">短肢剪力墙结构</div>
                <div class="desc">现浇结构至少30％</div>
              </div>
              <div class="item">
                <i class="iconfont icon-punch"></i>
                <div class="title">降板同层排水</div>
                <div class="desc">
                  下水位置不受限
                </div>
                <div class="desc">低噪声+舒适高度+维修便捷</div>
              </div>
              <div class="item">
                <i class="iconfont icon-brand"></i>
                <div class="title">热水循环</div>
                <div class="desc">
                  不同用水点

                </div>
                <div class="desc">同时供应恒温的热水</div>
              </div>
              <div class="item">
                <i class="iconfont icon-we"></i>
                <div class="title">断桥铝窗</div>
                <div class="desc">
                  高保温+隔冷

                </div>
                <div class="desc">热性好+隔音佳</div>
              </div>
            </div>
            <div class="warp">
              <div class="item">
                <i class="iconfont icon-square"></i>
                <div class="title">VELUX天窗</div>
                <div class="desc">
                  引光入室+增加自然通风

                </div>
                <div class="desc">自由升级定制</div>
              </div>
              <div class="item">
                <i class="iconfont icon-evaluate"></i>
                <div class="title">新风预留口</div>
                <div class="desc">
                  净化室内空气

                </div>
                <div class="desc">除菌、消毒、祛湿</div>
              </div>
              <div class="item">
                <i class="iconfont icon-flashlightopen"></i>
                <div class="title">空气源热泵</div>
                <div class="desc">
                  温度波动小，无风口
                </div>
                <div class="desc">冬夏一套设备</div>
              </div>
              <div class="item">
                <i class="iconfont icon-shop"></i>
                <div class="title">光伏屋面</div>
                <div class="desc">
                  绿色低碳
                </div>
                <div class="desc">节能减耗</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
import Banner from "@/components/banner.vue"

export default {
  name: "productDooryardDetail",
  data () {
    return {
      houseType: 0,
      cardList: [
        {
          image: require("../../../assets/images/xingjiabi1.jpg"),
        },
        {
          image: require("../../../assets/images/xingjiabi2.jpg"),
        },
        {
          image: require("../../../assets/images/xingjiabi3.jpg"),
        }
      ],
      hintCss: {
        "position": "absolute",
        "bottom": "0"
      }
    }
  },
  components: {
    Banner,
   Header2022,
   Footer2022,
  },
  computed: {
    houseData () {
      let houseData = null
      if (this.houseType) {
        houseData = {
          bannerList: [
            {
              image: require(`../../../assets/images/project/house-detail/${this.houseType}/banner.jpg`)
            }
          ],
          photoAlbum: [
            {
              image: require(`../../../assets/images/project/house-detail/${this.houseType}/photoAlbum1.jpg`),
              title: "效果图"
            },
            {
              image: require(`../../../assets/images/project/house-detail/${this.houseType}/photoAlbum2.jpg`),
              title: "效果图"
            },
            {
              image: require(`../../../assets/images/project/house-detail/${this.houseType}/photoAlbum3.jpg`),
              title: "效果图"
            },
            {
              image: require(`../../../assets/images/project/house-detail/${this.houseType}/photoAlbum4.jpg`),
              title: "效果图"
            }
          ],
          costEffective: require(`../../../assets/images/project/house-detail/${this.houseType}/costEffective.jpg`),
        }
      }
      return houseData
    },
    basicList(){
      let basicList = null
      if(this.houseType == 107){
        basicList = [
          [
            '楼层结构',
            '2层',
            '住宅类别',
            '庭院式住宅',
          ],
          [
            '装修情况',
            '按国家交付标准',
            '产权年限',
            '70年',
          ],
          [
            '容积率',
            '1.2',
            '绿化率',
            '37.6%',
          ],
        ]
      }else if(this.houseType == 142){
        basicList = [
          [
            '楼层结构',
            '3层',
            '住宅类别',
            '庭院式住宅',
          ],
          [
            '装修情况',
            '按国家交付标准',
            '产权年限',
            '70年',
          ],
          [
            '容积率',
            '1.2',
            '绿化率',
            '37.6%',
          ],
        ]
      }else if(this.houseType == 165){
        basicList = [
          [
            '楼层结构',
            '3层',
            '住宅类别',
            '庭院式住宅',
          ],
          [
            '装修情况',
            '按国家交付标准',
            '产权年限',
            '70年',
          ],
          [
            '容积率',
            '1.2',
            '绿化率',
            '37.6%',
          ],
        ]
      }else if(this.houseType == 207){
        basicList = [
          [
            '楼层结构',
            '3层',
            '住宅类别',
            '庭院式住宅',
          ],
          [
            '装修情况',
            '按国家交付标准',
            '产权年限',
            '70年',
          ],
          [
            '容积率',
            '1.2',
            '绿化率',
            '37.6%',
          ],
        ]
      }
      return basicList
    },
    vr(){
      let vr = ''
      if(this.houseType == 105){
        vr = 'https://pano8.p.kujiale.com/cloud/design/3FO4BTVSTL3G/show'
      }else if (this.houseType == 138){
        vr = 'https://pano8.p.kujiale.com/cloud/design/3FO4BJ7SGGHK/show'
      }else if (this.houseType == 163){
        vr = 'https://pano8.p.kujiale.com/cloud/design/3FO4BJBP4WK6/show'
      }else if (this.houseType == 210){
        vr = 'https://pano8.p.kujiale.com/design/3FO4B92PYYSN/show'
      }
      return vr
    },
  },
  mounted () {
    this.houseType = this.$route.params.houseType
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll: function () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 686) {
        this.hintCss = {
          "position": "fixed",
          "top": "0",
          "bottom": "initial"
        }
      } else {
        this.hintCss = {
          "position": "absolute",
          "bottom": "0",
          "top": "initial"
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.banner-wrap {
  position: relative;
}
.main {
  padding-top: 60px;
}
.icon {
  background-color: #fff;
  padding: 100px 0 118px 0;
}

.icon .warp {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.icon .warp:last-of-type {
  margin-bottom: 0;
}

.icon .warp .item {
  width: 200px;
  text-align: center;
  color: #303233;
}

.icon .warp .item .iconfont {
  font-size: 64px;
  line-height: 64px;
}

.icon .warp .item .title {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.icon .warp .item .desc {
  font-size: 14px;
  color: #666666;
}

.huxing {
  background-color: #f6f6f6;
  padding: 105px 0 74px;
}

.huxing .page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.huxing img {
  height: 100%;
  width: auto;
}

.huxing .right {
  margin-left: 168px;
}

.huxing .right .title {
  font-size: 40px;
  font-weight: 600;
  color: #303233;
  line-height: 56px;
}

.huxing .right .desc {
  font-size: 16px;
  color: #303233;
  line-height: 26px;
  margin-top: 47px;
}

.chewei {
  width: 100%;
}

.chewei img {
  width: 100%;
}

.shuang {
  padding: 111px 0 113px;
}

.shuang .title {
  font-size: 40px;
  color: #303233;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin-bottom: 46px;
}

.shuang .desc {
  font-size: 16px;
  color: #303233;
  text-align: center;
  margin: auto;
}

.shuang .warp {
  margin-top: 47px;
  display: flex;
  justify-content: space-between;
}

.shuang .warp .item {
  width: 285px;
  position: relative;
}

.shuang .warp .item .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.5);
}

.xingjiabi {
  width: 100%;
  height: 545px;
  // background-image: url('../../../assets/images/xingjiabi.jpg');
  background-size: 100% 100%;
}

.xingjiabi .page {
  height: 100%;
  display: flex;
  align-items: center;
}

#xingjiabi-banner {
  width: 500px;
}

.vr {
  padding: 100px 0;
}

.vr .title {
  font-size: 40px;
  color: #303233;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}

.vr .desc {
  font-size: 16px;
  color: #303233;
  text-align: center;
  line-height: 16px;
  margin-top: 50px;
}

.vr .content {
  width: 100%;
  margin-top: 50px;
}

.vr .content iframe {
  width: 100%;
  height: 675px;
}

.xiangce {
  background-color: #f6f6f6;
  padding: 60px 0;
}

.xiangce .title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #303233;
}

.xiangce .warp {
  margin-top: 19px;
  display: flex;
  justify-content: space-between;
}

.xiangce .warp .item {
  width: 285px;
  height: auto;
  position: relative;
}

.xiangce .warp .item img {
  width: 100%;
  height: auto;
}

.xiangce .warp .item .title {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.basic {
  padding-bottom: 60px;
}

.basic table {
  width: 100%;
  border: 1px solid #dddddd;
  text-align: center;
}

.basic table tr {
  height: 50px;
}

.basic table tr td {
  height: 50px;
  color: #303233;
}

.basic table tr td:nth-of-type(2n) {
  color: #666666;
}

.table-head th {
  text-align: left;
  padding: 0 15px;
  font-size: 16px;
  color: #303233;
}

.head {
  position: absolute;
}

.hint {
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.hint .page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.hint .page .right {
  display: flex;
}

.hint .page .right .area {
  font-size: 16px;
  margin-right: 30px;
}

.hint .page .right .area span {
  font-size: 26px;
  margin-right: 6px;
  line-height: 26px;
}

.hint .page .right .btn {
  font-size: 14px;
  background-color: #00cb87;
  color: #fff;
  padding: 8px 16px;
  line-height: 14px;
  border-radius: 100px;
}

.banner {
  width: 100%;
  height: 750px;
}
</style>
