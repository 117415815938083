<template>
  <el-carousel :trigger="trigger" :height="height"  :autoplay="autoplay" :interval="interval" :arrow="arrow" :type="type" :loop="loop" :direction="direction" :indicator-position="indicatorPosition" @change="change">
    <el-carousel-item v-for="(item,index) in bannerList" :key="index">
      <el-image :src="item.image" :alt="item.title" fit="cover" class="image-wrap" @click="linkClick(item.link)"></el-image>
      <p class="banner-title animated" :class=" index==selectedIndex ? 'fadeInUp' : '' " v-if="item.title && item.title!= 'off' && showtitle==true">{{item.title}}</p>
      <p class="banner-title animated image-title" :class=" index==selectedIndex ? 'fadeInUp' : '' " v-if="item.titleImage">
        <el-image :src="item.titleImage" :alt="item.title" fit="cover" class="image-wrap"></el-image>
      </p>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import utils from '@/common/utils'
export default {
  name: 'Banner',
  props:{
    bannerList:Array,
    height:{
      type:String,
      default:"750px"
    },
    trigger:{
      type:String,
      default:"click"
    },
    autoplay:{
      type:Boolean,
      default:true
    },
    interval:{
      type:Number,
      default:3000
    },
    arrow:{
      type:String,
      default:"hover"
    },
    type:String,
    loop:{
      type:Boolean,
      default: true
    },
    direction:{
      type: String,
      default:"horizontal"
    },
	showtitle: Boolean,
    indicatorPosition:String,
  },
  data () {
    return {
      selectedIndex:0,
    }
  },
  components: {

  },
  methods:{
    change:function(e){
      this.selectedIndex = e
    },
   linkClick:utils.debounce(function(url){
     if(url && url !=''){
       window.location.href=url;
     }
    },200,true),
  },
}
</script>

<style lang="scss" scoped>
.animated{
    animation-duration:.8s;
}
.image-wrap{
  width: 100%;
  height: 100%;
}
.bannerVideo{
  height: 100%;
}
.banner-title{
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -24px;
  width: 100%;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.image-title{
  position: absolute;
  top: 38%;
  left: 0;
  width: 100%;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  .image-wrap{
    width: 400px;
  }
}

</style>